<template>
    <div class="container">
        <b-overlay opacity="0.2" :show="loading" bg-color="white">
            <div class="row mt-2">
                <div class="col-12">
                    <h2 class="mb-2">{{$safe(request,'CariName') || '---'}} | Teklif No: {{$safe(request,'OfferCode') || '---'}}</h2>
                    <div class="row">
                        <div class="col-md-4 mb-1">
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Son Cevap Tarihi</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'LastAnswerDateStr') || '-'}}</h4>
                            </b-card>
                        </div>
                        <div class="col-md-4 mb-1" >
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Sözleşme Başlangıç Tarihi</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'OfferAgreementStartDate') || '-'}}</h4>
                            </b-card>
                        </div>
                        <div class="col-md-4 mb-1">
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Sözleşme Bitiş Tarihi</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'OfferAgreementEndDate') || '-'}}</h4>
                            </b-card>
                        </div>
                        <div class="col-md-4 mb-1" v-if="$safe(request,'InflationAffectValueActive')">
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Enflasyon Etki Oranı (%)</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'InflationAffectValue') || '-'}}%</h4>
                            </b-card>
                        </div>
                        <div class="col-md-4 mb-1" v-if="$safe(request,'FuelAffectValueActive')">
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Yakıt Etki Oranı (%)</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'FuelAffectValue') || '-'}}%</h4>
                            </b-card>
                        </div>
                        <div class="col-md-4 mb-1" v-if="$safe(request,'FuelAffectRateValueActive')">
                            <b-card body-class="p-1" class="mb-0">
                                <h5 class="text-muted">Yakıt Fiyatı Artış/Azalış Oranı (%)</h5>
                                <h4 class="mb-0 font-weight-bolder">{{$safe(request,'FuelAffectRateValue') || '-'}}%</h4>
                            </b-card>
                        </div>
                        <div class="col-12 mb-1">
                            <div v-if="request.OfferDocumentUrl != ''" class="row d-flex justify-content-end mb-1">
                                <div class="col-auto mb-1 mb-md-0">
                                    <button class="btn btn-primary text-nowrap waves-effect" v-on:click="downloadOfferDocument">
                                        <feather-icon icon="DownloadIcon" size="16" ></feather-icon>
                                        Teklif Şartnamesi İndir
                                    </button>
                                </div>
                            </div>
                            <b-card body-class="p-1" class="mb-0">
                                <div class="alert" :class="statusColor" v-if="$safe(request,'Status') != null">
                                    <div class="lightinig alert-body">
                                        {{ $safe(request,'StatusStr') }}
                                    </div>
                                </div>
                                <h3>Teklif Rotaları</h3>
                                <table class="table table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="w-16">Başlangıç Yeri</th>
                                            <th class="w-16">Bitiş Yeri</th>
                                            <th class="w-16">Stok</th>
                                            <th class="w-16">Araç Tipi</th>
                                            <th class="w-16">Taşıma Durumu</th>
                                            <th class="w-13 align-middle">{{($safe(request,'Status') == 3 || $safe(request,'Status') == 4) ? 'Anlaşılan Fiyat (₺)' : 'Fiyat Teklifi (₺)' }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="(offerItems || []).length > 0">
                                            <tr v-for="(oi,i) in offerItems" :key="i">
                                                <td>{{$safe(oi,'StartTransferRegionName') || '-'}}</td>
                                                <td>{{$safe(oi,'EndTransferRegionName') || '-'}}</td>
                                                <td>{{$safe(oi,'MaterialName') || '-'}}</td>
                                                <td>{{$safe(oi,'VehicleTypeName') || '-'}}</td>
                                                <td>{{$safe(oi,'VehicleTransportStatusName') || '-'}}</td>
                                                <td v-if="$safe(request,'Status') == 0">
                                                    <v-currency-input input-class="text-right form-control-sm" :value="oi.Price" v-on:update-value="currencyAmount => oi.Price = currencyAmount"></v-currency-input>
                                                </td>
                                                <td class="text-right font-weight-bolder bg-light-success" v-else>
                                                   <span>{{$safe(oi,'PriceStr') || '-'}}</span>
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-else>
                                            <td colspan="100" class="text-center">Bir teklif rotası bulunmamaktadır.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-card>
                        </div>
                        <div class="col-12" v-if="$safe(request,'Status') == 0">
                            <div class="text-right pb-1 sticky-bottom-zone">
                                <v-executer :loading="sending" text="Gönder" class="box-shadow-4" :action="send"></v-executer>
                            </div>
                        </div>
                        <div class="col-12 text-right" v-if="$safe(request,'Status') == 3">
                            <div class="text-right pb-1 sticky-bottom-zone">
                                <v-executer :loading="accepting" text="Sözleşme Onayı Ver" variant="success" class="box-shadow-4" :action="accept"></v-executer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { OfferApi } from '@/api/offer'
import { BCard, BOverlay } from 'bootstrap-vue'
import VCurrencyInput from '@/components/VCurrencyInput.vue'
import Additional from '@/utilities/additional'

export default {
    components:{
        BCard,
        BOverlay,
        VCurrencyInput
    },
    props:{

    },
    data(){
        return{
            id:this.$route.params?.id || null,
            request:{},
            loading:true,
            sending:false,
            updateData:{
                OfferId: null,
                OfferItemPrices: []
            },
            accepting:false
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        downloadOfferDocument(){
            Additional.GetBlobFile(this.request.OfferDocumentUrl).then(c=>{
                const name = 'Teklif Şartnamesi.pdf';  
                Additional.DownloadFile(c.data,name)
            })
        },
        get(){
            if(this.id){
                this.loading = true
                OfferApi.Get(this.id).then(res=>{
                    this.request = res.data.Data
                    if(res.data.Success){
                        this.loading = false
                    }else{
                        this.$result(res)
                    }
                }).catch(err=>{
                    this.$result(null,err)
                })
            }
        },
        send(){
            this.sending = true
            OfferApi.CarrierUpdatePrice({
                OfferId:this.id,
                OfferItemPrices:this.request.OfferItems || []
            }).then(res=>{
                this.sending = false
                if(res.data.Success){
                    this.get()
                }
            }).catch(err=>{
                this.$result(null,err)
                this.sending = false
            })
        },
        accept(){
            this.accepting = true
            OfferApi.ConvertToAgreement(this.id).then(res=>{
                this.accepting = false
                if(res.data.Success){
                    this.get()
                }
            }).catch(err=>{
                this.accepting = false
                this.$result(null,err)
            })
        }
    },
    computed:{
        statusColor(){
            if(this.request?.Status == 0) return 'alert-warning'
            else if(this.request?.Status == 1) return 'alert-primary'
            else if(this.request?.Status == 2) return 'alert-danger'
            else if(this.request?.Status == 3) return 'alert-secondary'
            else if(this.request?.Status == 4) return 'alert-success'
        },
        offerItems(){
            if(this.request){
                if(this.request.Status == 3 ||this.request.Status == 4){
                    return this.request.OfferItems?.filter(s=>s.IsAccepted) || []
                }else return this.request.OfferItems
            }else return []
        }
    }
}
</script>

<style>
.sticky-bottom-zone{
    position:sticky;
    bottom:0;
}
</style>